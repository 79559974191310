import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import logo from "../../../assets/images/logo-footer.png";
import { Icon } from "@iconify/react";
 
 
import "./Footer.scss";
import ContactUs from '../../contactUs/contactUs';
import { Link } from 'react-router-dom';


const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

 

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

 

  return (
    <>
    <div className="footer-main-section flex">
      <div className="footer-left flex flex-col gap-8">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="content">
          <p>
            Experience effortless guest engagement with Host Guru AI. Elevate your hospitality journey with the power of Host Guru AI.
          </p>
        </div>
      </div>
      <div className="footer-right flex text-white">
        <div className="links flex flex-col gap-4">
          <div className="heading">Platform</div>
          <div className="content">
            <a>AI Chatbot</a>
            <a>Booking Site Widget</a>
            <a>Knowledge Base</a>
            <a>Helpdesk</a>
          </div>
        </div>
        <div className="links flex flex-col gap-4">
          <div className="heading">Resources</div>
          <div className="content">
            <Link to="/blogs">Blogs</Link>
            <a>Doc</a>
            <a  className={`contact-us-button ${isModalOpen ? 'modal-show' : 'modal-hide'}`} onClick={handleModal}>Contact Us</a>
            <Link to='/privacy-policy'>Privacy Policy</Link>
          </div>
        </div>
        <div className="links flex flex-col gap-4">
          <div className="heading">Pages</div>
          <div className="content">
            <div className="icons flex gap-8">
              <a href="">
                {" "}
                <Icon icon="fa6-brands:linkedin" width="40px" height="40px" />
              </a>
              <a href="">
                {" "}
                <Icon
                  icon="ic:baseline-facebook"
                  color="white"
                  width="40px"
                  height="40px"
                />
              </a>
            </div>
            <a>@2023 Host Guru. All Rights Reserved</a>
          </div>
        </div>
      </div>
 
    </div>
    <Modal   open={isModalOpen} onOk={handleModal} onCancel={handleModal}
    centered={true}
    closable={false}
    footer	={null}
    width="1240px"
    >
    
    <ContactUs/>
    
          </Modal>
    </>

  );
};

export default Footer;
