import React from 'react'
import "./popularTopics.scss"
import profile1 from "../../../../assets/icons/profile1.svg"
import profile2 from "../../../../assets/icons/profile2.svg"
import profile3 from "../../../../assets/icons/profile3.svg"
import profile4 from "../../../../assets/icons/profile4.svg"
import profile5 from "../../../../assets/icons/profile5.svg"
import profile6 from "../../../../assets/icons/profile6.svg"
import profile7 from "../../../../assets/icons/profile7.svg"
import profile8 from "../../../../assets/icons/profile8.svg"
import card1 from "../../../../assets/images/cardimg1.png"
import card2 from "../../../../assets/images/cardimg2.png"
import card3 from "../../../../assets/images/cardimg3.png"
import card4 from "../../../../assets/images/cardimg4.png"
import card5 from "../../../../assets/images/cardimg5.png"
import card6 from "../../../../assets/images/cardimg6.png"
import card7 from "../../../../assets/images/cardimg7.png"
import card8 from "../../../../assets/images/cardimg8.png"
import { Link } from 'react-router-dom'
const PopularTopics = () => {
    const cardsData = [
        {
            id : 1,
            image : card1,
            date :'08.08.2021',
            cardHeading :'Dream destinations to visit this year in Paris',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile1,
            name :'By Jennifer Lawrence',
            title :'Thinker & Designer',


        },
        {
            id : 2,
            image : card2,
            date :'08.08.2021',
            cardHeading :'Richird Norton photorealistic rendering as real photos',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile2,
            name :'By David Arthur',
            title :'Designer',


        },
        {
            id : 3,
            image : card3,
            date :'08.08.2021',
            cardHeading :'Dream destinations to visit this year in Paris',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile3,
            name :'By Sarah Lawrence',
            title :'Journalist',


        },
        {
            id : 4,
            image : card4,
            date :'08.08.2021',
            cardHeading :'Dream destinations to visit this year in Paris',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile4,
            name :'By Jennifer Lawrence',
            title :'Thinker & Designer',


        },
        {
            id : 5,
            image : card5,
            date :'08.08.2021',
            cardHeading :'Art of Seasons: 40+ Bright Illustrations by Nature',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile5,
            name :'By David Tomas',
            title :'Thinkers',
        },
        {
            id : 6,
            image : card6,
            date :'08.08.2021',
            cardHeading :'The Anatomy of a Web Page and Basic Elements',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile6,
            name :'By Jennifer Lawrence',
            title :'Thinker & Designer',
        },
        {
            id : 7,
            image : card7,
            date :'08.08.2021',
            cardHeading :'Types of Contrast in User Interface Design',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile7,
            name :'By Andrey Edison',
            title :'Thinker & Designer',
        },
        {
            id : 8,
            image : card8,
            date :'08.08.2021',
            cardHeading :'Dream destinations to visit this year in Paris',
            cardPara :'Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.',
            profilePic :profile8,
            name :'By Sean Anderson',
            title :'Thinker & Designer',
        },
    ]
  return (
    <div className='popular-topics'>
      <div className="popular-topics-header">
                <h2>Popular topics</h2>
                <div className="popular-tabs">
                    <div className="popular-all-tabs">
                  <ul>
                    <li><span>All</span></li>
                    <li>Adventure</li>
                    <li>
                    Travel
                    </li>
                    <li>
                    Fashion
                    </li>
                    <li>
                    Technology
                    </li>
                    <li>
                    Branding
                    </li>
                  </ul>
                    </div>
                    <div className="view-all">
                        <p>View All</p>
                    </div>

                  
                </div>

      </div>
      <div className="popular-topics-cards-main-container">
        {cardsData.map(card => (
            <Link to="/blogs-detail">
                <div className="popular-card-container" key={card.id}>
                <div className="card-image">
                    <img src={card.image} alt="" />
                </div>
                <div className="card-content-section">
                    <p>
                   {card.date}
                    </p>
                    <h2>
                    {card.cardHeading}
                    </h2>
                    <p>
                    {card.cardPara}
                    </p>
    
                    <div className="card-profile">
                        <div className="profile-pic">
                            <img src={card.profilePic} alt="" />
                        </div>
                        <div className="profile-detail">
                            <h3>
                            {card.name}
                            </h3>
                            <span>
                         {card.title}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            </Link>
        )
       

        )}
        
      </div>

    </div>
  )
}

export default PopularTopics
