import React from 'react'
import logo from "../../../../assets/images/logo-footer.png"
import line from "../../../../assets/icons/line.svg"
import { Icon } from '@iconify/react';
import "./blogTwoHero.scss"
const BlogTwoHero = () => {
  return (
    <div className='blog-hero-two'>
     {/* <div className="blog-two-header">
        <div className="blog-two-header-logo">
            <img src={logo} alt="" />
        </div>
        <div className="blog-two-header-social">
            <Icon icon="pepicons-pencil:line-y" width="24px" height="24px"  style={{color: '#fff'}} />
            <Icon icon="ic:baseline-facebook" width="24px" height="24px"  style={{color: '#fff'}} />       
        <Icon icon="mdi:twitter" width="24px" height="24px"  style={{color: "#fff"}} />
        <Icon icon="mdi:youtube" width="24px" height="24px"  style={{color: '#fff'}} />
        <Icon icon="mdi:pinterest" width="24px" height="24px"  style={{color: '#fff'}} />
        <Icon icon="devicon-plain:behance" width="24px" height="24px"  style={{color: '#fff'}} />
        <Icon icon="pepicons-pencil:line-y" width="24px" height="24px"  style={{color: '#fff'}} />
            
            <Icon icon="iconamoon:search-light" width="24px" height="24px"  style={{color:' #fff'}} />
            </div>
            </div> */}

            <div className="blog-two-content">
                <div className="blog-two-content-travel">
                    <h3>TRAVEL</h3>
                </div>
                <div className="blog-two-content-heading">
                    <h1>
                    Richird Norton photorealistic rendering as real photos
                    </h1>
                    <div className="heading-bottom-content">
                        
                        <h2>
                        Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.
                        </h2>
                        <p>
                        By Jennifer Lawrence
                        </p>
                    </div>
                </div>
            </div>
     </div>
  )
}

export default BlogTwoHero
