import React from "react";
import "./home.scss";

/* Images */
import Header from "../../components/global/header/Header";
import Footer from "../../components/global/footer/Footer";
import Hero from "../../components/hero/Hero";
import HomeTrusted from "../../components/hometrusted/HomeTrusted";
import HomeFeatures from "../../components/homefeatures/HomeFeatures";
import HomeBenefits from "../../components/homeBenefits/HomeBenefits";
import HomeBlogs from "../../components/homeBlog/HomeBlogs";
import Reviews from "../../components/reviews/Reviews";
import NewReview from "../../components/reviews/NewReview";
import Chats from "../../components/chatassistance/chats";
import Integration from "../../components/integration/integration";
import Automation from "../../components/Automation/automation";
import Automationnew from "../../components/Automationnew/automationnew";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <HomeFeatures />
      <HomeBenefits />
      {/* <Automation /> */}
      <Automationnew />
      <Chats />
      <Integration />
      {/* <Reviews /> */}
      {/* <NewReview /> */}
      <HomeTrusted />
      <HomeBlogs />
      <Footer />
    </div>
  );
};

export default Home;
