import React, { useEffect } from "react";
import BlogOneHero from "../blogOne/blogOneHero/blogOneHero";
import BlogTwoHero from "./BlogTwoHero/blogTwoHero";
import BlogTwoMid from "./BlogTwoMid/blogTwoMid";
import Posts from "./Posts/posts";
import Footer from "../../../components/global/footer/Footer";
import Header from "../../../components/global/header/Header";

const BlogTwo = () => {
  useEffect(() => {
  
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Header />
      <BlogTwoHero />
      <BlogTwoMid />
      <Posts />
      <Footer />
    </div>
  );
};

export default BlogTwo;
