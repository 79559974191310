import React from 'react'
import "./posts.scss"
import post1 from "../../../../assets/images/post1.png"
import post2 from "../../../../assets/images/post2.png"
import post3 from "../../../../assets/images/post3.png"
import post4 from "../../../../assets/images/post4.png"
const Posts = () => {
    const cardData = [
        {
            id : 1,
            cardimg : post1,
            cardButton : 'ADVENTURE',
            cardDate : '08.08.2021',
            cardHeading : 'Thins to know before visiting Cave in Germany',
            cardPara : 'Progressively incentivize cooperative systems throughtechnically sound functionalities. Credibly productivateseamless data with flexible schemas.'
        },
        {
            id : 2,
            cardimg : post2,
            cardButton : 'FASHION',
            cardDate : '08.08.2021',
            cardHeading : 'Nina Smith vibrant work collab with Nike Dunk',
            cardPara : 'Progressively incentivize cooperative systems throughtechnically sound functionalities. Credibly productivateseamless data with flexible schemas.'
        },
        {
            id : 3,
            cardimg : post3,
            cardButton : 'TECHNOLOGY',
            cardDate : '08.08.2021',
            cardHeading : 'Richard Norton photorealistic rendering as real photos',
            cardPara : 'Progressively incentivize cooperative systems throughtechnically sound functionalities. Credibly productivateseamless data with flexible schemas.'
        },
        {
            id : 4,
            cardimg : post4,
            cardButton : 'FASHION',
            cardDate : '08.08.2021',
            cardHeading : '25 quality collectors toys inspired by famous films',
            cardPara : 'Progressively incentivize cooperative systems throughtechnically sound functionalities. Credibly productivateseamless data with flexible schemas.'
        },

    ]
  return (
    <div className='post-main-container'>
        <div className="post-content-container">
        <div className="post-header">
            <h1>Related Posts</h1>
        </div>
        <div className="post-cards-container">

            {
                cardData.map((data)=>(
                    <div className="post-card" key={data.id}>
                    <div className="post-card-image">
                        <img src={data.cardimg} alt="" />
                        
                        <div className="post-card-button">
                        <p>     {data.cardButton} </p> 
                        </div>
                    </div>
                    <div className="post-card-content">
                   
                     <p>
                     { data.cardDate }
                   
                    </p>
                    <h2>
                        {
                            data.cardHeading
                        }
                              
                        </h2>
                        <p>
                            {data.cardPara}
                      
                        </p>
                    </div>
                   
    
                </div>
                ))
            }
            
        </div>
        </div>
    </div>
  )
}

export default Posts
