import React from "react";
import "./homeTrusted.scss";
import stripe from "../../assets/icons/stripe.svg"
import booking from "../../assets/icons/booking.svg"
import airbnb from "../../assets/icons/airbnb.svg"
import trip from "../../assets/icons/tripadvisor.svg"
import agoda from "../../assets/icons/agoda.svg"


const HomeTrusted = () => {
  return (
    <div>
      <div className="trusted-container flex flex-col justify-between">
        <div className="trusted-top">
          {/* <span className="value-span">value</span> */}
          <h2>
            Trusted by <br /> <span> Many</span>
          </h2>
        </div>
        <div className="trusted-bottom">
          {/* <img src={trustedby} className="logo-home" alt="" /> */}
          {/* <marquee behavior="" direction="" scrollamount="20"> */}
            <div className="cards flex justify-center	 gap-12">
              
            <a href="https://stripe.com/" target="_blank"> <div className="card">
               <img src={stripe} alt="" />
              </div></a>
              <a href="https://www.booking.com/index.en-gb.html?aid=2311236;label=en-pk-booking-desktop-732gBu1H4WlF1HDSvlYKKAS652796017653:pl:ta:p1:p2:ac:ap:neg:fi:tikwd-334108349:lp9076904:li:dec:dm;ws=&gad_source=1&gclid=Cj0KCQiAw6yuBhDrARIsACf94RU1pFt1YSr3cnaVS4jM1OklBZv9umkYd9ui-napNp9mVgJVJx3JaDsaAs9wEALw_wcB" target="_blank"> 
              <div className="card">
                <img src={booking} alt="" />
              </div>
              </a>
              <a href="https://www.airbnb.com/" target="_blank">
              <div className="card">
                <img src={airbnb} alt="" />
              </div></a>
                <a href="https://www.tripadvisor.com/" target="_blank">
              <div className="card">
                <img src={trip} alt="" />
              </div></a>
              <a href="https://www.agoda.com/?cid=1844104&ds=qSTHVMzV09xeG2ZB" target="_blank">
              <div className="card">
                <img src={agoda} alt="" />
              </div></a>
{/* 
              <div className="card">
                <img src={trustedthree} alt="" />
              </div>
              <div className="card">
                <img src={trustedone} alt="" />
              </div>

              <div className="card">
                <img src={trustedtwo} alt="" />
              </div>

              <div className="card">
                <img src={trustedthree} alt="" />
              </div> */}
            </div>
          {/* </marquee> */}
        </div>
      </div>
    </div>
  );
};

export default HomeTrusted;
