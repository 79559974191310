import React from "react";
import FeatureSection from "../featureSection/FeatureSection";
import "./HomeFeatures.scss";

const HomeFeatures = () => {
  return (
    <div className="flex items-center flex-col pt-10 pb-16 core-feature">
      <h2 className="text-center text-7xl  font-bold pt-6 text-white">
        Our Core <span className="gradient-span-3 font-bold">Features</span>
      </h2>
      <FeatureSection
        d1="d1"
        feature="feature 1"
        title="AI Chat Support"
        para="Elevate your team's performance with AI-powered chat, seamlessly answering guest queries and supporting agents for exceptional service. Prioritize what matters most as AI streamlines routine tasks, allowing your team to thrive."
      />
      <FeatureSection
        d1="d2"
        feature="feature 2"
        title="AI-Powered Helpdesk"
        para="Proactively assist guests and streamline inquiries with our AI-driven solution. Smartly address guest questions, enabling your support agents to provide exceptional service while you concentrate on your priorities. Enhance efficiency and elevate guest experiences effortlessly. "
      />
      <FeatureSection
        d1="d3"
        feature="FEATURE 3"
        title="Conversion Tool"
        para="Elevate your team's performance with AI-powered chat, seamlessly answering guest queries and supporting agents for exceptional service. Prioritize what matters most as AI streamlines routine tasks, allowing your team to thrive. "
      />
      <FeatureSection   
        d1="d4"
        feature="feature 4"
        title="Tailored to Your Style"
        para="Customize your AI assistant's tone to suit your preferences and receive pre-approved messages on your phone. Guest Guru is specifically designed to adapt seamlessly to your unique hosting style "
      />
    </div>
  );
};

export default HomeFeatures;
