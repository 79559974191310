import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import fillstar from "../../assets/icons/Starfill.png";
import emptystar from "../../assets/icons/Starempty.png";
import review1 from "../../assets/images/review1.png";
import review2 from "../../assets/images/review2.png";
import "./MySwiper.css";

const NewReview = () => {
  const swiperParameters = {
    modules: [A11y, Navigation, Scrollbar],
    navigation: { enabled: false },
    breakpoints: {
      768: { slidesPerView: 2, spaceBetween: 16 },
      1024: { slidesPerView: 3, spaceBetween: 32 },
      1280: {
        slidesPerView: 2,
        speed: 1000,
        navigation: { enabled: true },
        centeredSlides: true,
        freeMode: { sticky: true, enabled: true },
        spaceBetween: 30,
      },
      // 1920: {
      //   scrollbar: { draggable: true, enabled: true },
      //   freeMode: { sticky: false, enabled: false },
      //   spaceBetween: 64,
      // },
    },
  };
  return (
    <>
      <Swiper {...swiperParameters}>
        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="white-card card">
              <div className="card-header flex gap-12 ">
                <div className="img">
                  <img src={review1} alt="" />
                </div>

                <div className="name flex flex-col gap-8">
                  <h2>Chris Blec</h2>
                  <div className="stars flex gap-6">
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={emptystar} alt="" />
                  </div>
                </div>
              </div>

              <div className="card-content">
                Thanks to the AI-driven rental system, managing our properties
                has never been easier. It's efficient, user-friendly, and has
                significantly reduced our workload.
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="white-card card">
              <div className="card-header flex gap-12 ">
                <div className="img">
                  <img src={review2} alt="" />
                </div>

                <div className="name flex flex-col gap-8">
                  <h2>Korpi</h2>
                  <div className="stars flex gap-6">
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={emptystar} alt="" />
                  </div>
                </div>
              </div>

              <div className="card-content">
                Thanks to the AI-driven rental system, managing our properties
                has never been easier. It's efficient, user-friendly, and has
                significantly reduced our workload.
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="white-card card">
              <div className="card-header flex gap-12 ">
                <div className="img">
                  <img src={review2} alt="" />
                </div>

                <div className="name flex flex-col gap-8">
                  <h2>Korpi</h2>
                  <div className="stars flex gap-6">
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={emptystar} alt="" />
                  </div>
                </div>
              </div>

              <div className="card-content">
                Thanks to the AI-driven rental system, managing our properties
                has never been easier. It's efficient, user-friendly, and has
                significantly reduced our workload.
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="white-card card">
              <div className="card-header flex gap-12 ">
                <div className="img">
                  <img src={review2} alt="" />
                </div>

                <div className="name flex flex-col gap-8">
                  <h2>Korpi</h2>
                  <div className="stars flex gap-6">
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={emptystar} alt="" />
                  </div>
                </div>
              </div>

              <div className="card-content">
                Thanks to the AI-driven rental system, managing our properties
                has never been easier. It's efficient, user-friendly, and has
                significantly reduced our workload.
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="white-card card">
              <div className="card-header flex gap-12 ">
                <div className="img">
                  <img src={review2} alt="" />
                </div>

                <div className="name flex flex-col gap-8">
                  <h2>Korpi</h2>
                  <div className="stars flex gap-6">
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={fillstar} alt="" />
                    <img src={emptystar} alt="" />
                  </div>
                </div>
              </div>

              <div className="card-content">
                Thanks to the AI-driven rental system, managing our properties
                has never been easier. It's efficient, user-friendly, and has
                significantly reduced our workload.
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="swiper-slide-text swiper-slide-text-79be">
              Slide 6
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="swiper-slide-text swiper-slide-text-79be">
              Slide 7
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="swiper-slide-text swiper-slide-text-79be">
              Slide 8
            </div>
          </div>
        </SwiperSlide> */}
        {/* 
        <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="swiper-slide-text swiper-slide-text-79be">
              Slide 9
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide className="swiper-slide-3f38">
          <div className="swiper-slide-content swiper-slide-content-cd26">
            <div className="swiper-slide-text swiper-slide-text-79be">
              Slide 10
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
      {/* <div className="swiper-container "></div> */}
    </>
  );
};

export default NewReview;
