import React, { useEffect, useState } from "react";
import "./comingsoon2.scss";
import logo from "../../assets/images/comingsoonlogo.svg";
import line from "../../assets/icons/Line 2.svg";
import discord from "../../assets/icons/discord.svg";
import insta from "../../assets/icons/insta.svg";
import facebook from "../../assets/icons/facebook.svg";
import linkedIn from "../../assets/icons/linkedin.svg";
import heroimg1 from "../../assets/images/pic1.png";
import heroimg2 from "../../assets/images/pic2.png";
import heroimg3 from "../../assets/images/pic3.png";
import heroimg4 from "../../assets/images/pic4.png";
import heroimg5 from "../../assets/images/pic5.png";
import heroimg6 from "../../assets/images/pic6.png";
import heroimg7 from "../../assets/images/pic7.png";
import heroimg8 from "../../assets/images/pic8.png";
import backpic from "../../assets/images/comingsoonback.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { Link } from "react-router-dom";

const initialValues = {
  name: "",
  email: "",
  number: "", // Change the field name to "number"
  numberOfListing: "",
};

const singUpSchema = yup.object({
  name: yup.string().min(2).required("Please enter your name"),
  number: yup.string().required("Please enter phone number"),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      "Please enter a valid email"
    )
    .required("Please enter your email"),
  numberOfListing: yup
    .string()
    .min(1)
    .required("Please enter number of listings"),
});

const Comingsoon2 = () => {
  const comingsoon = useRef();
  const { values, errors, touched, handleChange, handleSubmit, resetForm } =
    useFormik({
      initialValues: initialValues,
      validationSchema: singUpSchema,
      onSubmit: async (values, { resetForm }) => {
        console.log("", values);
        // const formData = new FormData();
        // formData.append("name", values.name ? values.name : "");
        // formData.append("email", values.email ? values.email : "");
        // formData.append("number", values.number ? values.number : "");
        // formData.append("numberOfListing", values.number ? values.number : "");
        // console.log(formData);
        try {
          const abc = await emailjs.sendForm(
            "service_sij9v37",
            "template_roode3h",
            comingsoon.current,
            "CI1H2MsTj_iPD-uYF"
          );
          console.log(abc);
        } catch (e) {
          console.log(e);
        }

        resetForm();
      },
    });

  const images = [
    heroimg1,
    heroimg2,
    heroimg3,
    heroimg4,
    heroimg5,
    heroimg6,
    heroimg7,
    heroimg8,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      const newIndex = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(newIndex);
    };

    const timer = setTimeout(changeImage, 2000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, images.length]);

  return (
    <div className="coming-soon-new">
      <div className="coming-soon-new-main">
        <div className="coming-soon-header">
          <a href="/">
            {" "}
            <img src={logo} alt="" />
          </a>
        </div>

        <div className="coming-soon-content">
          <div className="coming-soon-left">
            <div className="coming-soon-container">
              <img src={line} alt="" />
              <p>Coming Soon</p>
            </div>
            <h2>Unlock Hospitality Excellence with Hostguru AI</h2>
            <p>24/7 AI messaging for short term rental hosts.</p>
            <form action="" onSubmit={handleSubmit} ref={comingsoon}>
              <div className="coming-soon-form">
                <input
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Name*"
                />{" "}
                {errors.name && touched.name ? (
                  <p className="form-error">{errors.name}</p>
                ) : null}
                <input
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Email*"
                />{" "}
                {errors.email && touched.email ? (
                  <p className="form-error">{errors.email}</p>
                ) : null}
                <input
                  type="number"
                  name="number"
                  value={values.number}
                  onChange={handleChange}
                  placeholder="Phone Number"
                />{" "}
                {errors.number && touched.number ? (
                  <p className="form-error">{errors.number}</p>
                ) : null}
                <input
                  type="number"
                  name="numberOfListing"
                  value={values.numberOfListing}
                  onChange={handleChange}
                  placeholder="Number Of Listings"
                />{" "}
                {errors.numberOfListing && touched.numberOfListing ? (
                  <p className="form-error">{errors.numberOfListing}</p>
                ) : null}
              </div>
              <div className="email-button">
                <button type="submit">Email me when it's done!</button>
              </div>
              <ToastContainer />
            </form>
          </div>
          <div className="coming-soon-right">
            <img src={images[currentImageIndex]} className="logo-home" alt="" />
          </div>
        </div>
        <div className="coming-soon-social">
          <a
            href="https://www.facebook.com/profile.php?id=61554289450254"
            target="_blank"
          >
            <img src={facebook} alt="" />
          </a>

          <a
            href="https://www.linkedin.com/company/hostguru-ai/"
            target="_blank"
          >
            <img src={linkedIn} alt="" />
          </a>
          <Link to='/blogs'>
          <div className="blogs-button">
            Blogs
          </div></Link>
          <Link to='/privacy-policy'>
          <div className="blogs-button">
            Privacy Policy
          </div></Link>

          {/* <img src={facebook} alt="" />
          <img src={twitter} alt="" /> */}
        </div>
      </div>
      <div className="coming-soon-back">
        <img src={backpic} alt="" />
      </div>
    </div>
  );
};

export default Comingsoon2;
