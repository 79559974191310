import React, { useEffect } from "react";
import BlogOneHero from "./blogOneHero/blogOneHero";
import PopularTopics from "./PopularTopics/popularTopics";
import Fashion from "./Fashion/fashion";
import Footer from "../../../components/global/footer/Footer";
import EditorPick from "./EditorPick/editorPick";
import Header from "../../../components/global/header/Header";

const BlogOne = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div className="blog-main" style={{ background: "#F8F9FA" }}>
      <Header />
      <BlogOneHero />
      <PopularTopics />
      <Fashion />
      <EditorPick />
      <Footer />
    </div>
  );
};

export default BlogOne;
