import React from "react";
import "./BlogSection.scss";
// images
import blog1 from "../../assets/images/blog1.png";
// import blog2 from '../../assets/images/blog2.png'
// import blog3 from '../../assets/images/blog3.png'

const BlogSection = ({ title, para, blog_img }) => {
  return (
    <div className="card-container">
      <div className="flex flex-col justify-between items-center blog-card dark-bg">
        <div className="animation"></div>
        <img src={blog_img} alt="" />
        <h5 className="py-5 px-4">{title}</h5>
        <p className=" px-4">{para}</p>
        <button type="button" className="button-theme1 text-white ">
          Read More
        </button>
      </div>
    </div>
  );
};

export default BlogSection;
