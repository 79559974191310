import React, { useState } from 'react';
import './contactUs.scss';
import contactimg from '../../assets/images/contactpic.png';
import closeIcon from '../../assets/icons/contactus-cross.svg';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  const handleCloseButton = () => {
    setIsVisible(false);
  };

  const handleContactLinkClick = () => {
    setIsVisible(true); // Reset visibility state to true when contact link is clicked
  };

  if (!isVisible) {
    return null; // Render nothing if isVisible is false
  }

  return (
    <div className={`contact-us-main ${isVisible ? 'modal-show' : 'modal-hide'}`}>
      <div className="close-button">
        <div className="close-left">{/* <h2>kfdsa;f</h2> */}</div>
        <div className="close-right" onClick={handleCloseButton}>
          <img src={closeIcon} alt="Close" />
        </div>
      </div>
      <div className="content-container">
        <div className="contact-us-left">
          <div className="left-content">
            <h2>Get in touch</h2>
            <p>We are here for you! How can we help?</p>
            <div className="input-fields">
              <p>Name</p>
              <input type="text" />
            </div>
            <div className="input-fields">
              <p>Email</p>
              <input type="email" />
            </div>
            <div className="input-fields">
              <p>Message</p>
              <input type="text" className="h-24" />
            </div>
            <div className="submit-button">
              <button>Submit</button>
            </div>
          </div>
        </div>
        <div className="contact-us-right">
          <img src={contactimg} alt="Contact" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
