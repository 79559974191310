import React from "react";
import "./FeatureSection.scss";
import feature1 from "../../assets/images/feature1.svg";
import feature2 from "../../assets/images/feature2.png";
import feature3 from "../../assets/images/feature3.png";
import feature4 from "../../assets/images/feature4.png";
import solidCheck from "../../assets/images/Solid check square.png";

const FeatureSection = ({ feature, title, para, d1 }) => {
  return (
    <div className="flex justify-center card">
      {d1 === "d1" ? (
        <section class="feature-section flex w-75 ">
          <div className="feature-img-container">
            <img src={feature1} alt="" srcset="" />
          </div>
          <div className="feature-txt-container text-white flex flex-col justify-between   items-start ">
            <span className="text-white">{feature}</span>
            <h3 className="text-white">{title}</h3>
            <p>{para}</p>
            <ul className="mt-8 pb-6">
              <li className="flex">
                {/* <input type="checkbox" /> Lorem ipsum */}
                <img src={solidCheck} alt="" /> Enhanced Guest Interaction
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" />Agent Support Reinvented
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" /> Thriving Team Dynamics
              </li>
            </ul>
            {/* <button type="button" class="button-theme1 text-white">
              Read More
            </button> */}
          </div>
        </section>
      ) : d1 === "d2" ? (
        <section class="feature-section feature-sectio2 flex w-75">
          <div className="feature-img-container">
            <img src={feature2} alt="" srcset="" />
          </div>
          <div className="feature-txt-container flex flex-col justify-between   items-start">
            <span>{feature}</span>
            <h3>{title}</h3>
            <p>{para}</p>
            <ul className="mt-8 pb-6">
              <li className="flex">
                <img src={solidCheck} alt="" /> Proactive Guest Assistance
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" />
                Empowered Support Teams
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" /> Effortless Efficiency
                Enhancement
              </li>
            </ul>
            {/* <button type="button" class="button-theme1 text-white">
              Read More
            </button> */}
          </div>
        </section>
      ) : d1 === "d3" ? (
        <section class="feature-section flex w-75 ">
          <div className="feature-img-container">
            <img src={feature3}  alt="" srcset="" />
          </div>
          <div className="feature-txt-container text-white flex flex-col justify-between   items-start ">
            <span className="text-white">{feature}</span>
            <h3 className="text-white">{title}</h3>
            <p>{para}</p>
            <ul className="mt-8 pb-6">
              <li className="flex">
                {/* <input type="checkbox" /> Lorem ipsum */}
                <img src={solidCheck} alt="" /> Effortless Guest Engagement
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" />Optimized Agent Support
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" /> Strategic Task Prioritization
              </li>
            </ul>
            {/* <button type="button" class="button-theme1 text-white">
              Read More
            </button> */}
          </div>
        </section>
      ): d1 ==="d4" ?(
<section class="feature-section feature-sectio2 flex w-75">
          <div className="feature-img-container">
            <img src={feature4} alt="" srcset="" />
          </div>
          <div className="feature-txt-container flex flex-col justify-between   items-start">
            <span>{feature}</span>
            <h3>{title}</h3>
            <p>{para}</p>
            <ul className="mt-8 pb-6">
              <li className="flex">
                <img src={solidCheck} alt="" />Personalize AI assistant's tone
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" />
                Pre-approved messages
              </li>
              <li className="flex">
                <img src={solidCheck} alt="" /> Adapt to your hosting style
              </li>
            </ul>
            {/* <button type="button" class="button-theme1 text-white">
              Read More
            </button> */}
          </div>
        </section>
      ):
      (<p className="hidden">Unsupported class</p>)
      }
    </div>
  );
};

export default FeatureSection;
