import React from 'react'
import "./editorPick.scss"

const EditorPick = () => {
  
  return (
    <div className='editor-pick'>
        <div className="editor-pick-head">
        <h2>Editor’s Pick</h2>
        </div>
        <div className="editor-cards-container">
            <div className="editors-card">
              <div className="editor-buttons">
                <div className="adventure">
                  <p>ADVENTURE</p>
                </div>
                <div className="adventure">
                  <p>FASHION</p>
                </div>
              </div>
              <div className="editor-content">
                <div className="editor-date">
                  <p>08.08.2021</p>
                </div>
                <h2>Richird Norton photorealistic rendering as real photos</h2>
                <p>
                Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.
                </p>
              </div>
            </div>
            <div className="editors-card">
              <div className="editor-buttons">
                <div className="adventure">
                  <p>ADVENTURE</p>
                </div>
                <div className="adventure">
                  <p>FASHION</p>
                </div>
              </div>
              <div className="editor-content">
                <div className="editor-date">
                  <p>08.08.2021</p>
                </div>
                <h2>Richird Norton photorealistic rendering as real photos</h2>
                <p>
                Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.
                </p>
              </div>
            </div>
        </div>
      
    </div>
  )
}

export default EditorPick
