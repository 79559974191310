import React, { useState } from "react";
import BlogSection from "../blogSection/BlogSection";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeBlog.scss";
import blog1 from "../../assets/images/blog1.png";
import blog2 from "../../assets/images/blog2.png";
import blog3 from "../../assets/images/blog3.png";
const HomeBlogs = () => {
  const sliderRef = React.useRef(null);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setIsLastSlide(next === sliderRef.current.props.children.length - 2.5);
      setIsFirstSlide(next == 0);
    },

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: false,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const handleNext = () => {
    console.log("check", sliderRef.current);
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const handlePrev = () => {
    console.log("check", sliderRef.current);

    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className=" home-blog-main flex items-center  m-auto pd pd-100-bottom">
      <div className="heading">
        <h2 className="text-center  text-5xl font-bold pb-10">
          <span className="gradient-span font-bold">Explore </span> <br />
          Our Insights
        </h2>
      </div>

      <div className="slider-container  ">
        <Slider ref={sliderRef} {...settings}>
          <div className="slide">
            <BlogSection
              blog_img={blog1}
              title="How to build your Short Term Rental listing knowledge base"
              para="Even in a perfect short-term rental, there are areas for improvement. Regular refinement ensures a superior guest experience, positive reviews, and increased demand. "
            />
          </div>
          <div className="slide" style={{ width: 600 }}>
            <BlogSection
              blog_img={blog2}
              title="The only Listing Knowledge Base guide you'll ever need"
              para="Ready to explore the realm of AI for Short-Term Rentals? Your search ends here – we've got you covered!"
            />
          </div>
          <div className="slide" style={{ width: 600 }}>
            <BlogSection
              blog_img={blog3}
              title="What to Review in Your New Short Term Rental Property During an Owner Walkthrough"
              para="As a Short-Term Rental manager, seamless onboarding is crucial. Prioritize guest satisfaction, offering local insights for a memorable stay and encouraging repeat bookings. "
            />
          </div>
          <div className="slide" style={{ width: 500 }}>
            <BlogSection
              blog_img={blog1}
              title="How to build your Short Term Rental listing knowledge base"
              para="Even in a perfect short-term rental, there are areas for improvement. Regular refinement ensures a superior guest experience, positive reviews, and increased demand. "
            />
          </div>
        </Slider>

        <div className="arrows-container flex gap-12 items-center justify-start mb-8 ">
          <div
            className={` ${isFirstSlide ? " prev-arrow" : "firt-slide"}`}
            onClick={handlePrev}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color={isFirstSlide ? " #6d43ff" : "white "}
              width="25"
              height="25"
            />
          </div>
          <div
            className={` ${isLastSlide ? " last-slide" : "next-arrow"}`}
            onClick={handleNext}
          >
            <Icon
              icon="ph:arrow-left-bold"
              color={isLastSlide ? "#6d43ff" : "white"}
              width="25"
              height="25"
              rotate={2}
            />
          </div>
        </div>

        {/* <BlogSection
          blog_img={blog1}
          title="What to Review in Your New Short Term Rental Property During an Owner Walkthrough"
          para="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lacus leo, feugiat id faucibus in, rhoncus eu nisi. "
        /> */}
      </div>
    </div>
  );
};

export default HomeBlogs;
