import React, { useEffect } from 'react'
import "./privacy.scss"
import Header from '../../components/global/header/Header'
const Privacy = () => {
useEffect(()=>{
  window.scrollTo(0,0)
},[])
  return (
    <>
    <Header />
    <div className='privacy-main'>
      <div className="privacy-header">
        <h1>Privacy Policy
</h1></div>
<div className="privacy-content">

      <p>
      It is Hostguru.ai's commitment to respect your privacy and adhere to all relevant laws and regulations concerning the collection of personal information about you. This includes data collected through our website, www.hostguru.ai, as well as any other subdomains and affiliated sites we own and operate.
      </p>
      <p>
      Personal information" encompasses any data that can identify you, including but not limited to: name, address, date of birth, device details, payment information, and usage patterns on websites or online services. </p>
      <p>
      Please note that third-party sites and services linked on our site maintain their own privacy policies. We encourage you to review their privacy practices when navigating away from our site.
      </p>
      <p>This Privacy Policy is effective as of 03/01/2024 and was last updated on 02.05.2023.
</p>
<h2>Information Collection:
</h2>
<p>
Information collected falls into two categories: "voluntarily provided" and "automatically collected."
</p>
<p>
"Voluntarily provided" information refers to data actively shared by you when using our services or participating in promotions.
</p>
<p>
"Automatically collected" information includes data sent by your devices when interacting with our products and services.
</p>
<h2>Log Data:
</h2>
<p>When visiting our website, our servers may automatically record standard data provided by your web browser. This may include your device's IP address, browser type, pages visited, timestamps, and other details.</p>
<p>Additionally, we may collect data on errors encountered during site usage, including technical information about your device and the nature of the error.
</p>
<h2>Device Data:
</h2>
<p>
Interactions with our website or services may result in the automatic collection of device data, such as device type, operating system, unique identifiers, settings, and geo-location information. Collection methods may vary based on individual device and software settings.

</p>
<h2>Personal Information:
</h2>
     <p>We may request personal information when you engage with our services, including but not limited to:
</p>
<ul>
  <li>
  - Name

  </li>
  <li>
  - Email

  </li>
  <li>
  - Social media profiles

  </li>
  <li>
  - Date of birth

  </li>
  <li>
  - Phone/cell phone number

  </li>
  <li>- Home/email address
</li>
</ul>
<h2>
User-Generated Content:

</h2>
<p>Materials voluntarily provided by users for publication, processing, or use on our platform constitute user-generated content. Once published, such content may be publicly accessible.
</p>
<h2>Transaction Data:
</h2>
<p>
Data generated during the normal operation of our platform, including transaction records, user profiles, analytics, and other metrics, may be collected and stored.

</p>
<h2>Legal Grounds for Processing:
</h2>
<p>
We collect and use personal information only when we have a legitimate reason to do so, and only to the extent necessary to provide our services.

</p>
<h2>Use of Information:
</h2>
<p>We collect personal information for various purposes, including:
</p>
<ul>
  <li>- Providing and improving our services
</li>
  <li>- Personalizing user experiences
</li>
  <li>- Communication
</li>
  <li>- Analysis and business development
</li>
  <li>- Marketing and advertising
</li>
  <li>- Legal compliance and dispute resolution
</li>
  <li>- Security and fraud prevention
</li>
</ul>
<h2>Data Security:
</h2>
<p>
We employ commercially acceptable measures to protect personal information from loss, theft, and unauthorized access. However, no method of electronic transmission or storage is entirely secure.

</p>
<h2>Data Retention:
</h2>
<p>We retain personal information only as long as necessary for the purposes outlined in this policy. Data may be retained beyond account deletion for legal, accounting, or archival purposes.
</p>
<h2>Minors:
</h2>
<p>We cannot verify the age of our users and thus are not responsible for unsupervised use by minors. Users must represent themselves as of legal age.
</p>
<h2>Disclosure to Third Parties:
</h2>
<p>Personal information may be disclosed to third parties under certain circumstances, as outlined in our policy.
</p>
<h2>User Rights:
</h2>
<p>Users have rights regarding their personal information, including access, correction, objection, and deletion. We provide mechanisms for users to exercise these rights.
</p>
<h2>Use of Cookies:
</h2>
<p>
We use cookies to collect information about user activities on our site, allowing us to tailor content based on preferences.

</p>
<h2>Asset Transfers:
</h2>
<p>In the event of an acquisition or cessation of business, personal information may be transferred to acquiring entities.
</p>
<h2>Limits of Policy:
</h2>
<p>We do not control external sites linked on our website and are not responsible for their privacy practices.
</p>
<h2>Policy Changes:
</h2>
<p>We may update our privacy policy to reflect changes in business processes, practices, or regulations. Significant changes will be communicated to users.
</p>
<h2>Additional GDPR Compliance:
</h2>
<p>We comply with GDPR regulations, distinguishing between data controllers and processors and ensuring lawful processing of personal data.
</p>
<h2>User Rights under GDPR:
</h2>
<p>Users have rights regarding their personal data under GDPR, including restriction, objection, data portability, and deletion.<br/>
For any queries or requests regarding personal information, please contact us using the details provided in this policy.

</p>
</div>
      
    </div>
    </>
  )
}

export default Privacy
