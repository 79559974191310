import React from 'react'
import { Icon } from '@iconify/react';
import './ComingSoon.css'

const ComingSoon = () => {
    return (
        <div>
            <div className='comingsoon-main-container'>
                <div className='comingsoon-inner-container'>
                    <div className='brandtxthead'>
                        <img src="/assets/img/textbrand.svg" alt="" />
                    </div>
                    <div className='comingsoon-center-container'>
                        <h1>coming soon</h1>
                        <h3>We are currently working hard on this page!</h3>
                        <div className='comingsoon-input-container'>
                            <input type="text" placeholder='Enter Address' name="first_name" />
                            <button className='btn-theme'>Submit</button>
                        </div>
                    </div>
                    <div className='comingsoon-socialIcons-container'>
                        <a href='#'><Icon className='social-icon' width="35px" height="35px" icon="ic:baseline-discord" /></a>
                        <a href='#'><Icon className='social-icon' width="35px" height="35px" icon="mdi:instagram" /></a>
                        <a href='#'><Icon className='social-icon' width="35px" height="35px" icon="ri:facebook-fill" /></a>
                        <a href='#'><Icon className='social-icon' width="35px" height="35px" icon="iconoir:x" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon;
