import React from 'react'
import "./integration.scss"
import hostaway from "../../assets/images/hostaway.svg"
import guestfy from "../../assets/images/guestfy.svg"
import hostfully from "../../assets/images/hostfuly.svg"
import Link from 'antd/es/typography/Link'
const Integration = () => {
  return (
    <div className='integration-main'>
        <div className="integration-content">
        
        <div className="intergration-left">
        <h2>
        <span>Integrated</span>  with the
        </h2>
        <p>
        property management systems
        </p>
        </div>
        <div className="intergration-right">
            <a href="https://www.hostaway.com/" target='_blank' >
            <div className="left-logo-container">
                <img src={hostaway} alt="" />
            </div></a>
            <a href="https://www.guesty.com/" target='_blank'> <div className="left-logo-container">
                <img src={guestfy} alt="" />
            </div></a>
            <a href="https://www.hostfully.com/" target='_blank'> <div className="left-logo-container">
                <img src={hostfully} alt="" />
            </div></a>
        </div>
        </div>
    </div>
  )
}

export default Integration
