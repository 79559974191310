import React, { useEffect, useState } from "react";
import "./Hero.scss";
import chaticon from "../../assets/images/chaticon.svg";
import crossicon from "../../assets/images/crossicon.svg";
import last from "../../assets/icons/lasticon.svg";
import editicon from "../../assets/icons/edit.svg";
import sendmsgicon from "../../assets/icons/sendicon.svg"
import backIcon from "../../assets/icons/backicon.svg"
import minimizeIcon from "../../assets/icons/minimizeicon.svg"
import heroimg1 from "../../assets/images/heroimg1.png";
import heroimg2 from "../../assets/images/heroimg2.png";
import heroimg3 from "../../assets/images/heroimg3.png";
import heroimg4 from "../../assets/images/heroimg4.png";
import heroimg5 from "../../assets/images/heroimg5.png";
import heroimg6 from "../../assets/images/heroimg6.png";
import heroimg7 from "../../assets/images/heroimg7.png";

const Hero = () => {
  const images = [heroimg1, heroimg2, heroimg3, heroimg4, heroimg5, heroimg6, heroimg7];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      const newIndex = (currentImageIndex + 1) % images.length;
      setCurrentImageIndex(newIndex);
    };

    const timer = setTimeout(changeImage, 2000);

    return () => clearTimeout(timer);
  }, [currentImageIndex, images.length]);

  




  const [isIconToggled, setIsIconToggled] = useState(false);
  const [currentSection, setCurrentSection] = useState("");

  const handleClick = () => {
    setIsIconToggled(!isIconToggled);
  }

  const handleChatClick = (e) => {
    e.stopPropagation();
  };

  const [chatback, setIchatback] = useState(true);
  const handelBackChat = () => {
    setIchatback(false)
  }

  return (
    <div className="hero-main-container">
      <div className="hero-main-inner-container">
        <div className="hero-centered-container">
          <div className="hero-content">
            <h1>
              Unlock Hospitality Excellence with{" "}
              <span className="gradient-span">Hostguru AI</span>
            </h1>
            <h3>24/7 AI messaging for short term rental hosts.</h3>
            <div className="flex justify-start sm:justify-start align-center gap-12 buttons">
              <button type="button" className="button-theme1 text-white">
                Request Demo
              </button>
            </div>
          </div>

          <div className="hero-img rounded-2xl">
            <img
              src={images[currentImageIndex]}
              className="logo-home"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="chat-bot" onClick={handleClick}>
        <img src={isIconToggled ? crossicon : chaticon}
          alt=""
          className={isIconToggled ? "rotate" : ""} />

        {chatback ?
          <div className={`host-guru-ai ${isIconToggled ? "show" : ""}`} onClick={handleChatClick}>
            <div className="host-guru-top">
              <div className="host-guru-top-left">
                <img src={backIcon} alt="" className="pointer" onClick={handelBackChat} />
                <p>Your Chats</p>
              </div>
              <div className="host-guru-top-right" onClick={handleClick}>
                <img src={minimizeIcon} alt="" className=" pointer" />
              </div>

            </div>

            <div className="message-section">
              <div className="host-guru-messages" >
                <div className="message-container">
                  <p>
                    Hi there!
                  </p>
                </div>
                <div className="message-container">
                  <p>
                    Welcome to Lawvocate AI. What brought you in Today?            </p>
                </div>

              </div>

              <div className="request-demo">
                <div className="request-btn">
                  <p>
                    Request a demo
                  </p>
                </div>
                <div className="request-btn">
                  <p>
                    Can you connect me with the human?
                  </p>
                </div>
                <div className="request-btn">
                  <p>
                    I need Support
                  </p>
                </div>
              </div>
            </div>

            <div className="write-msg">
              <input type="text" placeholder="Write a message" />

              <div className="send-message">
                <img src={sendmsgicon} alt="" />
              </div>

            </div>


          </div>
          :
          <div className={`your-chats ${isIconToggled ? "show" : ""}`} onClick={handleChatClick}>
            <div className="your-chats-top">
              <p>Your Chats</p>
              <img src={editicon} alt="" />
            </div>
            <div className="" >

              <div className="your-chats-second read" onClick={() => setIchatback(true)}>
                <h2>Hostguru Bot</h2>
                <p>Can you connect me with the human?</p>
              </div>
              <div className="your-chats-last unread " onClick={() => setIchatback(true)}>
                <div className="last-left">
                  <h2>Hostguru Bot</h2>
                  <p>Can you connect me with the human?</p>
                </div>
                <div className="last-right ">
                  <img src={last} alt="..." />
                </div>
              </div>
              <div className="your-chats-last unread" onClick={() => setIchatback(true)}>
                <div className="last-left">
                  <h2>Hostguru Bot</h2>
                  <p>Can you connect me with the human?</p>
                </div>
                <div className="last-right">
                  <img src={last} alt="..." />
                </div>
              </div>
            </div>

          </div>
        }
      </div>
    </div>
  );
};

export default Hero;
