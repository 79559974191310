import React from 'react'
import './Login.scss'
import { Link } from 'react-router-dom'

const Login = () => {
    return (
        <div className='login-main-container'>
            <div className='brandtxthead'>
                <img src="/assets/img/textbrand.svg" alt="" />
            </div>
            <div className='login-center-container'>
                <div className='login-light-box'>
                    <div className='login-dark-box bg-white'>
                        <h5>Log in</h5>
                        <div className='flex flex-col'>
                            <input type="text" placeholder='Email' />
                            <input type="password" placeholder='Password' />
                            <button type="button" class="button-theme1 text-white">Log in</button>
                            <Link to="/resetpassword" className='p-login'>Reset <span>Password ?</span></Link>
                        </div>
                        {/* <p>By signing up you agree to the <span>Term & Conditions </span>and our <span><br />Privacy Policy</span></p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login