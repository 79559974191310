import React from 'react'
import './SignUp.scss'
import { Link } from 'react-router-dom';

const SignUp = () => {
    return (
        <div className='signup-main-container'>
            <div className='brandtxthead'>
                <img src="/assets/img/textbrand.svg" alt="" />
            </div>
            <div className='signup-center-container'>
                <div className='signup-light-box'>
                    <div className='signup-dark-box bg-white'>
                        <h5>Sign up</h5>
                        <div className='flex flex-col'>
                            <input type="text" placeholder='Email' />
                            <input type="text" placeholder='Username' />
                            <input type="password" placeholder='Password' />
                            <button type="button" class="button-theme1 text-white">Sign up</button>
                            <p>Already have an account? <span><Link to="/login">Sign in</Link></span></p>
                        </div>
                        <p>By signing up you agree to the <span>Term & Conditions </span>and our <span><br />Privacy Policy</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;