import React from 'react'
import "./automationnew.scss"
import first from "../../assets/icons/first.svg"
import second from "../../assets/icons/second.svg"
import third from "../../assets/icons/third.svg"
import fourth from "../../assets/icons/fourth.svg"
import fifth from "../../assets/icons/fifth.svg"
const Automationnew = () => {
  return (
    <div className='automation-new-main'>
      <div className="automation-new-head">
        <h2>
        The Strategic Edge of  <span>Automation</span>
        </h2>
        <p>
        Give your team the operational superpower that makes everyone’s lives easier.
        </p>
      </div>
      <div className="polygon-row1">
        <div className=" empty-polygon1">
            <div className="polygon-inner">
               {/* <img src={first} alt="" />
                <p>
                Message Approval Rate
                </p>
                <h2>
                96%
                </h2> */}
            </div>
       
        </div>
        <div className="polygon-container">
            <div className="polygon-inner">
            
                <img src={first} alt="" />
                <p>
                Message Sent to Guest
                </p>
                <h2>
                1.2 Million
                </h2>
            </div>
       
        </div>
        <div className="polygon-container">
            <div className="polygon-inner">
            <img src={second} alt="" />
                <p>
                Message Approval Rate
                </p>
                <h2>
                96%
                </h2>
            </div>
       
        </div>
        <div className=" empty-polygon1">
            <div className="polygon-inner">
              {/* <img src={first} alt="" />
                <p>
                Message Approval Rate
                </p>
                <h2>
                96%
                </h2> */}
            </div>
        </div>
      </div>
      <div className="polygon-row2">
        <div className=" empty-polygon2">
            <div className="polygon-inner2">
               {/* <img src={first} alt="" />
                <p>
                Message Approval Rate
                </p>
                <h2>
                96%
                </h2> */}
            </div>
       
        </div>
        <div className="polygon-container2">
            <div className="polygon-inner2">
            
            <img src={third} alt="" />
                <p>
                Average Response time
                </p>
                <h2>
                46sec
                </h2>
            </div>
       
        </div>
        <div className="polygon-container2">
            <div className="polygon-inner2">
            <img src={fourth} alt="" />
                <p>
                Country Service
                </p>
                <h2>
                60+
                </h2>
            </div>
       
        </div>
        <div className="polygon-container2">
            <div className="polygon-inner2">
            <img src={fifth} alt="" />
                <p>
                Language Spoken
                </p>
                <h2>
                110+
                </h2>
            </div>
       
        </div>
        <div className=" empty-polygon2">
            <div className="polygon-inner2 ">
              {/* <img src={first} alt="" />
                <p>
                Message Approval Rate
                </p>
                <h2>
                96%
                </h2> */}
            </div>
       
        </div>
      </div>

    </div>
  )
}

export default Automationnew
