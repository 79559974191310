import React from 'react'
import "./chats.scss"
import chatimg from "../../assets/images/chatimg.png"
import efficient from "../../assets/images/userfriendly.svg"
import userfriendly from "../../assets/images/userfriendly2.svg"
import background from "../../assets/images/chatsback.png"
const Chats = () => {
  return (

    <div className='chats'>
    <div className='chat-main-container'>

        <div className="chats-top">
        <h2>
     Discover <span>HostGuru</span>  Chat Assistance for Seamless 
Rental Management
     </h2>
     <p>
     Our chatbot provides instant support and streamlines your rental management process. Experience hassle-free property management today!
     </p>
    <div className="chat-image">
        <img src={chatimg} alt="" />
    </div>
        </div>
    

    <div className="chat-cards">
        <div className="user-friendly">
            <img src={efficient} alt="" />
            <h3>
            Efficients
            </h3>
            <p>
            Our chat assistance automates tasks, reduces response time, and enhances customer satisfaction.
            </p>
        </div>
        <div className="user-friendly">
            <img src={userfriendly} alt="" />
            <h3>
            User- Friendly
            </h3>
            <p>
            With our intuitive chatbot, you can easily manage properties and communicate with tenants.            </p>
        </div>
    </div>

    </div>

    <div className="chats-background">
        <img src={background} alt="" />
    </div>
    </div>
  )
}

export default Chats
