import React, { useState } from "react";
import "./Header.scss";
import logo from "../../../assets/images/hostguru-logo.png";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const [topbarShow, setTopbarShow] = useState(false);
  const location = useLocation();
  const toggleTopBar = () => {
    setTopbarShow(!topbarShow);
  };
  return (
    <div>
      <div className="nav-main-container flex justify-between items-center">
        <div className="flex logo-section">
        <Link to='/home'> <img src={logo} className="logo-home" alt="" /></Link> 
    {topbarShow ? 
          <Icon
          
            icon="radix-icons:cross-2"
            color="#5865F2"
            width="35"
            height="35"
            onClick={toggleTopBar}
            className="nav-menu-icon"
          /> :
          <Icon

            icon="heroicons-solid:menu"
            color="#5865F2"
            width="35"
            height="35"
            onClick={toggleTopBar}
            className="nav-menu-icon"
          />
    }
        </div>
        <div
          className={`nav-content  ${topbarShow ? "showtopbar" : "hidetopbar"}`}
        >
          <div className={`nav-head ${location.pathname === '/home' || location.pathname === '/privacy-policy' ? 'nav-blue-color' : ''}`}>
            <ul className={`flex gap-16 ${location.pathname ==='/blogs-one' ? 'blue' : ''}`}>
              {/* <li>
                <a href="#">home</a>
              </li> */}
              <li>
                <a  href="#">features</a>
              </li>
              {/* <li>
                <a href="#">testimonials</a>
              </li> */}
              <li>
                <Link to="/blogs">blogs</Link>
              </li>
              {/* <li>
                <Link to="/privacy-policy">privacy policy</Link>
              </li> */}
            </ul>
          </div>
          <button type="button" className="button-theme1 text-white nav-btn">
            Get a Demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
