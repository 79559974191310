import React from "react";
import "./HomeBenefits.scss";
// Images
import benefitsImg from "../../assets/images/benefitsimg.svg";
import fast from "../../assets/icons/fast.png";
import scheduling from "../../assets/icons/scheduling.png";
import cost from "../../assets/icons/cost.png";
import selection from "../../assets/icons/selection.png";
import service from "../../assets/icons/service.png";
import privacy from "../../assets/icons/privacy.png";

const HomeBenefits = () => {
  return (
    <div className="benefits-sectio-main flex justify-center flex-col items-center mt-5">
      <section className="benefits-section m-auto w-90">
        <div className="benefits-left-section">
          <div>
            <h2 className="text-blue">
              Smarter Tool <span className="">Benefits</span>
            </h2>
            <p>
              Utilize our smart tool to streamline tasks and enhance overall
              workflow efficiency.
            </p>
          </div>
          <div>
            <img src={benefitsImg} alt="" />
          </div>
        </div>
        <div className="benefits-right-section">
          <div className="card">
            <img src={fast} alt="" />
            <h5>Fast</h5>
            <p>
            Fully automated communication with guests ensures an elevated guest experience without increasing workload.
            </p>
          </div>
          <div className="card">
            <img src={scheduling} alt="" />
            <h5>Call Scheduling</h5>
            <p>
            Automatically answer guest inquiries and provide accurate information about your property, the neighbourhood and things to do in your area.
            </p>
          </div>
          <div className="card">
            <img src={cost} alt="" />
            <h5>Cost-Effective</h5>
            <p>
            Reduce costs and increase operational efficiency by allowing AI to become your assistant in all aspects of your business.
            </p>
          </div>
          <div className="card">
            <img src={selection} alt="" />
            <h5>Wide Selection</h5>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lacus leo, feugiat id faucibus in, rhoncus
eu nisi. 
            </p>
          </div>
          <div className="card">
            <img src={service} alt="" />
            <h5>24/7 service</h5>
            <p>
            Choose from an extensive range of properties tailored to your preferences. Our platform ensures a diverse selection to meet your unique needs and preferences.
            </p>
          </div>
          <div className="card">
            <img src={privacy} alt="" />
            <h5>Privacy</h5>
            <p>
            Your privacy is our top concern. Rest easy with our commitment to safeguarding your personal information
            </p>
          </div>
        </div>
      </section>

      {/* <div className="benefits-bottom-section">
        <p>
          Our <span>Clients </span> Says About Us
        </p>
      </div> */}
    </div>
  );
};

export default HomeBenefits;
