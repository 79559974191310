import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.scss';
// Pages
import ComingSoon from './pages/comingsoon/ComingSoon';
import Home from './pages/home/Home';
import SignUp from './pages/signup/SignUp';
import Login from './pages/login/Login';
import ForgetPass from './pages/resetpassword/ForgetPass';
import { useState } from 'react';
import Comingsoon2 from './pages/comingsoon2/comingsoon2';
import BlogOne from './pages/blogs/blogOne/blogOne';
import BlogTwo from './pages/blogs/blogTwo/blogTwo';
import Privacy from './pages/privacy/privacy';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<ComingSoon />} /> */}
          <Route path="/" element={<Comingsoon2 />} />
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/resetpassword" element={<ForgetPass />} />
          <Route path="/blogs" element={<BlogOne />} />
          <Route path="/blogs-detail" element={<BlogTwo />} />
          <Route path='/privacy-policy' element={<Privacy/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
