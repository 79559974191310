import React from 'react'
import "./fashion.scss"
import line from "../../../../assets/icons/line.svg"
const Fashion = () => {
  return (
    <div className='fashion-main'>
        <div className="fashion-content">
            <div className="fashion">
                <p>FASHION</p>
            </div>
            <div className="fashion-content-main">
                <h1>Richird Norton photorealistic rendering as real photos</h1>
                <p>Progressively incentivize cooperative systems through technically sound functionalities. The credibly productivate seamless data.</p>
                <img src={line} alt="" />
                <p>08.08.2021</p>
            </div>
        </div>
        
    </div>
  )
}

export default Fashion
