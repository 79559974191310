import React from "react";
import "./blogOneHero.scss";
import logo from "../../../../assets/images/logo-footer.png";
import line from "../../../../assets/icons/line.svg";
import { Icon } from "@iconify/react";
const BlogOneHero = () => {
  return (
    <div className="blog-hero-one">
      {/* <div className="blog-one-header ">
        <div className="blog-one-header-logo">
          <img src={logo} alt="" />
        </div>
        <div className="blog-one-header-social">
          <Icon
            icon="pepicons-pencil:line-y"
            width="24px"
            height="24px"
            style={{ color: "#fff" }}
          />
          <Icon
            icon="ic:baseline-facebook"
            width="24px"
            height="24px"
            style={{ color: "#5865F2" }}
          />
          <Icon
            icon="mdi:twitter"
            width="24px"
            height="24px"
            style={{ color: "#5865F2" }}
          />
          <Icon
            icon="mdi:youtube"
            width="24px"
            height="24px"
            style={{ color: "#5865F2" }}
          />
          <Icon
            icon="mdi:pinterest"
            width="24px"
            height="24px"
            style={{ color: "#5865F2" }}
          />
          <Icon
            icon="devicon-plain:behance"
            width="24px"
            height="24px"
            style={{ color: "#5865F2" }}
          />
          <Icon
            icon="pepicons-pencil:line-y"
            width="24px"
            height="24px"
            style={{ color: "#fff" }}
          />

          <Icon
            icon="iconamoon:search-light"
            width="24px"
            height="24px"
            style={{ color: " #fff" }}
          />
        </div>
      </div> */}

      <div className="blog-one-content">
        <div className="blog-one-content-travel">
          <h3>TRAVEL</h3>
        </div>
        <div className="blog-one-content-heading">
          <h1>Richird Norton photorealistic rendering as real photos</h1>
          <div className="heading-bottom-content">
            <span>08.08.2021</span>
            <img src={line} alt="" />
            <h2>
              Progressively incentivize cooperative systems through technically
              sound functionalities. The credibly productivate seamless data.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogOneHero;
