import React from 'react'
import "./blogTwoMid.scss"
import line from "../../../../assets/icons/middleline.svg"
import img1 from "../../../../assets/images/midimg1.png"
import img2 from "../../../../assets/images/midimg2.png"
import profile from "../../../../assets/icons/profilepic.svg"
import { Icon } from '@iconify/react';

const BlogTwoMid = () => {
  return (
    <div className='middle-main-container'>
      <div className="middle-left-container">
        <p>08.08.2021</p>
        <img src={line} alt="" />
        <p>4 minutes</p>
      </div>
      <div className="middle-right-container">
        <p>
        Seamlessly syndicate cutting-edge architectures rather than collaborative collaboration and idea-sharing. Proactively incubate visionary interfaces whereas premium benefits. Seamlessly negotiate ubiquitous leadership skills rather than parallel ideas. Dramatically visualize superior interfaces for best-of-breed alignments. Synergistically formulate performance based users through customized relationships. Interactively deliver cross-platform ROI via granular systems. Intrinsicly enhance effective initiatives vis-a-vis orthogonal outsourcing. Rapidiously monetize market-driven opportunities with multifunctional users. Collaboratively enhance visionary opportunities through revolutionary schemas. Progressively network just in time customer service without real-time scenarios.
        </p>
        <p>
        Synergistically drive e-business leadership with unique synergy. Compellingly seize market positioning ROI and bricks-and-clicks e-markets. Proactively myocardinate timely platforms through distributed ideas. Professionally optimize enabled core competencies for leading-edge sources. Professionally enhance stand-alone leadership with innovative synergy. Rapidiously generate backend experiences vis-a-vis long-term high-impact relationships.
        </p>
        <div className="mid-images">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
        </div>
        <p>
        Efficiently empower seamless meta-services with impactful opportunities. Distinctively transition virtual outsourcing with focused e-tailers.
        </p>
        <h2>“ Monotonectally seize superior mindshare rather than efficient technology. ” </h2>
        <p>Compellingly enhance seamless resources through competitive content. Continually actualize 24/365 alignments for resource-leveling platforms. Energistically enhance high standards in models and professional expertise. Intrinsicly iterate extensible metrics for prospective opportunities. Continually develop leading-edge experiences through quality e-services.</p>

        <div className="mid-profile-section">
            <div className="profile-buttons-container">
                <div className="profile-button">
                ADVENTURE
                </div>
                <div className="profile-button">
                PHOTO
                </div>
                <div className="profile-button">
                DESIGN
                </div>
            </div>
            <div className="profile-content">
                <div className="profile-side">
                    <div className="profile-image-side">
                        <img src={profile} alt="" />
                    </div>
                    <div className="profile-name-side">
                            <h2>By Jennifer Lawrence</h2>
                            <span>Thinker & Designer</span>
                    </div>
                </div>
                <div className="profile-social-side">
                <Icon icon="ic:baseline-facebook" width="24px" height="24px"  style={{color: '#5865F2'}} />       
        <Icon icon="mdi:twitter" width="24px" height="24px"  style={{color: "#5865F2"}} />
        {/* <Icon icon="mdi:youtube" width="24px" height="24px"  style={{color: '#5865F2'}} /> */}
        <Icon icon="mdi:pinterest" width="24px" height="24px"  style={{color: '#5865F2'}} />
        <Icon icon="devicon-plain:behance" width="24px" height="24px"  style={{color: '#5865F2'}} />
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default BlogTwoMid
